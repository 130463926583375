@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300&display=swap');

* {
    margin: 0;
    padding: 0;
}

::-webkit-scrollbar {
    display: none;
}

*,
:after,
:before {
    box-sizing: border-box;
}

body,
html {
    background: url("../images/bg-pc.png") no-repeat center center;
    background-attachment: fixed;
    background-size: 100% 100%;
    min-height: 100vh;
    overflow-x: hidden;
    width: 100vw;
}

.main,
.wrapper {
    max-width: 100%;
    padding: 0 20px;
    width: 700px;
    margin: auto;
}

.header {
    margin: auto;
    padding: 40px;
    width: 90%;
    padding-bottom: 20px;
    cursor: pointer;
}

.header img {
    width: 100%;
}

.body-title {
    display: flex;
    justify-content: center;
    /* align-items: center; */
    padding: 10px 0;
}

.body-text {
    border-radius: 32.782px;
    border-bottom: 5.303px solid #2DE381;
    background: #006719;
    box-shadow: 0px 5.596930503845215px 7.196053504943848px 7.196053504943848px rgba(0, 0, 0, 0.15);
    padding: 20px;
}

.body-box {
    padding-top: 15px;
}

.body-box .label {
    color: #F8E64F;
    font-family: 'Roboto', sans-serif;
    font-size: 23.987px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.48px;
    padding: 20px;
}

.box-input {
    padding-top: 10px;
    margin: 0 20px;
}

.box-input .textarea {
    height: 223.877px;
    display: flex;
    width: 100%;
    height: 223.877px;
    padding: 15px;
    align-items: center;
    flex-shrink: 0;
    border-radius: 20.789px;
    border: 0.8px solid #ADADAD;
    background: #F0F0F0;
    color: #818181;
    font-family: Roboto;
    font-size: 23.987px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 0.48px;
}

.textarea:focus {
    border: 1px solid red;
}

.body-box .form-control {
    display: flex;
    width: 100%;
    padding: 14.392px;
    align-items: center;
    border-radius: 61.566px;
    border: 0.8px solid #ADADAD;
    background: #F0F0F0;
    color: #818181;
    font-family: 'Roboto', sans-serif;
    font-size: 23px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 0.48px;
}

.button {
    display: flex;
    width: 303.833px;
    padding: 15px 0;
    justify-content: center;
    align-items: center;
    border-radius: 55.969px;
    /* background: url("../images/button.png"); */
    /* background: linear-gradient(270deg, #0E763F 0%, #26FF83 100%); */
    color: #FFF;
    text-align: center;
    font-family: Roboto;
    font-size: 34.381px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 1.375px;
    margin: auto;
    cursor: pointer;
}

.button1 {
    display: flex;
    /* Sử dụng flexbox để căn giữa */
    justify-content: center;
    /* Căn giữa theo chiều ngang */
    align-items: center;
    /* Căn giữa theo chiều dọc (nếu cần) */
}

.image-button {
    background: none;
    /* Xóa nền mặc định của nút */
    border: none;
    /* Xóa viền mặc định */
    padding: 0;
    /* Xóa padding của nút */
    cursor: pointer;
    /* Thay đổi con trỏ chuột thành hình bàn tay khi hover */
}

.image-button img {
    width: 100%;
    /* Điều chỉnh kích thước ảnh */
    height: auto;
}

.body-box:last-child {
    padding-bottom: 10px;
}

.btn-back {
    position: absolute;
    width: 30px;
    right: 1rem;
    top: 1rem;
    height: auto;
}

.btn-back img {
    width: 100%;
}

@media (max-width: 679px) {

    body,
    html {
        background: url("../images/bg-mobile.png") no-repeat center center;
        background-attachment: fixed;
        background-size: 100% 100%;
    }

    .body-title {
        width: 50%;
    }

    .body-title img {
        width: 100%;
    }

    .body-box .label {
        padding-left: 0;
        font-size: 18px;
    }

    .body-text {
        padding: 20px;
    }

    .body-box .form-control {
        font-size: 14px;
    }

    .body-box .textarea {
        font-size: 14px;
        height: 145px;
    }

    .button {
        width: 190.833px;
        padding: 10.991px 0px;
        font-size: 20.381px;
    }

    .body-box:last-child {
        padding-bottom: unset;
    }
}

@media (max-width: 480px) {
    .body-title {
        width: 45%;
    }

    .body-title img {
        width: 100%;
    }

    .body-box .label {
        padding-left: 0;
        font-size: 16px;
    }

    .body-text {
        padding: 16px;
    }

    .body-box .form-control {
        font-size: 13px;
    }

    .body-box .textarea {
        font-size: 13px;
        height: 100px;
    }

    .button {
        width: 190px;
        padding: 10px 0;
        font-size: 18px;
    }

    .body-box:last-child {
        padding-bottom: unset;
    }

    .box-input {
        padding-top: 10px;
    }
}

@media (max-width: 375px) {
    .header {
        padding-bottom: 0;
    }

    .body-box {
        padding-top: 15px;
    }

    .body-box .form-control {
        padding: 8px;
    }

    .body-box .form-control {
        font-size: 12px;
    }

    .body-box .textarea {
        font-size: 12px;
        height: 80px;
    }

    .button {
        width: 140px;
        padding: 8px 0;
        font-size: 14px;
    }
}